import React, { FunctionComponent as FC } from "react";
import "./Board.css";
import { useTickingClock as useTickingTime } from "../hooks";
import { ArrivalTimeData, Time } from "../types";
import { isNil } from "ramda";

type BoardProps = {
  arrivalTimes: ArrivalTimeData[];
  line?: string;
  direction?: string;
  minimumLines: number;
  maximumLines: number;
  loading: boolean;
};

export const Board: FC<BoardProps> = ({
  arrivalTimes,
  minimumLines,
  maximumLines,
  line,
  loading,
  direction,
}) => {
  const currentTime = useTickingTime();

  return loading ? (
    <div className="loading-indicator">loading</div>
  ) : (
    <div className="board">
      <div className="arrival-times">
        {filterArrivals(arrivalTimes, line, direction).map((t, i) => {
          if (i > maximumLines - 1) return null;
          return <ArrivalTime number={(i + 1).toString()} data={t} key={i} />;
        })}
        {paddingLines(
          filterArrivals(arrivalTimes, line, direction),
          minimumLines
        )}
      </div>
      <Clock currentTime={currentTime} />
    </div>
  );
};

const filterArrivals = (
  arrivals: ArrivalTimeData[],
  line?: string,
  direction?: string
): ArrivalTimeData[] => {
  return filterByDirection(filterByLine(arrivals, line), direction);
};

const filterByLine = (
  arrivals: ArrivalTimeData[],
  line?: string
): ArrivalTimeData[] => {
  return line ? arrivals.filter((a) => a.lineName === line) : arrivals;
};

const filterByDirection = (
  arrivals: ArrivalTimeData[],
  direction?: string
): ArrivalTimeData[] => {
  return direction
    ? arrivals.filter((a) => a.direction === direction)
    : arrivals;
};

const paddingLines = (
  arrivalTimes: ArrivalTimeData[],
  minimumLines: number
): any[] => {
  var diff = arrivalTimes.length - minimumLines;
  if (diff < 0) {
    diff = Math.abs(diff);
    const lines = [];
    for (let i = 0; i < diff; i++) {
      lines.push(<ArrivalTime key={Math.random()} />);
    }
    return lines;
  }
  return [];
};

type ArrivalTimeProps = {
  number?: string;
  data?: ArrivalTimeData;
};
const ArrivalTime: FC<ArrivalTimeProps> = ({ number, data }) => {
  return (
    <div className="arrival-time" onClick={() => console.log(data)}>
      <span className="number-bullet">{number}</span>
      <span className="destination">
        <p>{data?.destination}</p>
      </span>
      <span className="minutes">
        {(isNil(data?.timeToStation)
          ? ""
          : Math.floor(data!.timeToStation / 60).toString()) +
          (isNil(data?.timeToStation) ? "" : " mins")}
      </span>
    </div>
  );
};

type ClockProps = { currentTime: Time };
const Clock: FC<ClockProps> = ({ currentTime }) => {
  return (
    <div className="clock">
      <span className="hours">{currentTime.hours}</span>
      <span className="minutes">:{currentTime.minutes}:</span>
      <span className="seconds">{currentTime.seconds}</span>
    </div>
  );
};
