import React, { FC } from "react";
import { Station, Mode } from "../types";
import "./StationSign.css";
import dlrLogo from "../images/dlr.png";
import tubeLogo from "../images/underground.png";
import busLogo from "../images/bus.png";
import { trimStationLabels } from "../utils/index";

type StationSignProps = {
  station: Station;
};

export const StationSign: FC<StationSignProps> = ({ station }) => {
  return (
    <div className="station-sign-container">
      <div className={"station-sign " + station.mode}>
        <span className="logo">
          <img src={resolveLogo(station.mode)} alt="logo" />
        </span>
        <span className="name">
          {trimStationLabels(station.name).toUpperCase()}
        </span>
      </div>
    </div>
  );
};

export const resolveLogo = (mode: Mode): string => {
  switch (mode) {
    case "dlr":
      return dlrLogo;
    case "tube":
      return tubeLogo;
    case "bus":
      return busLogo;
    default:
      return "";
  }
};
