import React, { FC, useEffect } from "react";
import { Radio } from "antd";
import { ArrivalTimeData } from "../types";
import { uniq } from "ramda";
import "./LineSelector.css";

const defaultLine: string = "";

type LineSelectorProps = {
  line: string;
  lineSetter: React.Dispatch<React.SetStateAction<string>>;
  arrivals: ArrivalTimeData[];
};

export const LineSelector: FC<LineSelectorProps> = ({
  line,
  lineSetter,
  arrivals,
}) => {
  useEffect(() => {
    lineSetter(defaultLine);
  }, [lineSetter]);

  const uniqueLines = uniq(arrivals.map((a) => a.lineName)).sort();
  if (uniqueLines.length === 0) return <></>;

  return (
    <div className="line-selector-container">
      <Radio.Group
        value={line}
        defaultValue={defaultLine}
        size="large"
        className="line-radio-group"
        onChange={(e) => {
          console.log("selected", e.target.value);
          lineSetter(e.target.value);
        }}
      >
        {uniqueLines.sort().map((line) => (
          <Radio.Button
            className={line.replace("&", "and").replace(" ", "-")}
            key={line}
            value={line}
          >
            {line}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export {};
