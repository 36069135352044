import React, { FC, useEffect, useState } from "react";
import { Station } from "../types";
import { AutoComplete } from "antd";
import { allStations } from "../stations";
import "./StationSelector.css";
import { resolveLogo } from "./StationSign";

const defaultStation: Station = {
  naptan: "940GZZLUCYF",
  name: "Canary Wharf Underground Station",
  mode: "tube",
};

type StationSelectorProps = {
  stationSetter: React.Dispatch<React.SetStateAction<Station>>;
  lineSetter: React.Dispatch<React.SetStateAction<string>>;
};

export const StationSelector: FC<StationSelectorProps> = ({
  stationSetter,
  lineSetter,
}) => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<Station[]>([]);

  useEffect(() => {
    stationSetter(defaultStation);
  }, [stationSetter]);

  const onSearch = (searchText: string) => {
    setOptions(
      !searchText
        ? []
        : allStations.filter((s) =>
            s.name
              .toLowerCase()
              .replace(".", "")
              .replace("'", "")
              .includes(
                searchText.toLowerCase().replace(".", "").replace("'", "")
              )
          )
    );
  };

  const onSelect = (data: string) => {
    stationSetter(options.filter((o) => o.name === data)[0]);
    lineSetter("");
  };
  const onChange = (data: string) => {
    setValue(data);
  };

  return (
    <div className="station-selector-container">
      <AutoComplete
        className="selector"
        allowClear={true}
        value={value}
        options={options.map((o) => ({
          value: o.name,
          label: (
            <div>
              <img
                className="dropdown-list-icon"
                style={{ height: "20px", paddingRight: "5px" }}
                src={resolveLogo(o.mode)}
                alt="logo"
              />
              <span>{o.name}</span>
            </div>
          ),
        }))}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        placeholder="Search..."
      />
    </div>
  );
};

export {};
