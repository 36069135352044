import { DLRStation } from "../types";

export const dlrStations: DLRStation[] = [
  { naptan: "940GZZDLWHM", name: "West Ham DLR Station", mode: "dlr" },
  { naptan: "940GZZDLWSV", name: "West Silvertown DLR Station", mode: "dlr" },
  { naptan: "940GZZDLWFE", name: "Westferry DLR Station", mode: "dlr" },
  { naptan: "940GZZDLSTD", name: "Stratford DLR Station", mode: "dlr" },
  { naptan: "940GZZDLISL", name: "Island Gardens DLR Station", mode: "dlr" },
  { naptan: "940GZZDLGRE", name: "Greenwich Rail Station", mode: "dlr" },
  { naptan: "940GZZDLBEC", name: "Beckton DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLSHS",
    name: "Stratford High Street DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLCGT", name: "Canning Town DLR Station", mode: "dlr" },
  { naptan: "940GZZDLPOP", name: "Poplar DLR Station", mode: "dlr" },
  { naptan: "940GZZDLCLA", name: "Crossharbour DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLCUT",
    name: "Cutty Sark (for Maritime Greenwich) DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLMUD", name: "Mudchute DLR Station", mode: "dlr" },
  { naptan: "940GZZDLEIN", name: "East India DLR Station", mode: "dlr" },
  { naptan: "940GZZDLKGV", name: "King George V DLR Station", mode: "dlr" },
  { naptan: "940GZZDLDEP", name: "Deptford Bridge DLR Station", mode: "dlr" },
  { naptan: "940GZZDLBOW", name: "Bow Church DLR Station", mode: "dlr" },
  { naptan: "940GZZDLSHA", name: "Shadwell Station", mode: "dlr" },
  { naptan: "940GZZDLGRE", name: "Greenwich DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLLCA",
    name: "London City Airport DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLABR", name: "Abbey Road DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLCUS",
    name: "Custom House (for ExCel) DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLALL", name: "All Saints DLR Station", mode: "dlr" },
  { naptan: "940GZZDLDEV", name: "Devons Road DLR Station", mode: "dlr" },
  { naptan: "940GZZDLLEW", name: "Lewisham DLR Station", mode: "dlr" },
  { naptan: "940GZZDLRVC", name: "Royal Victoria DLR Station", mode: "dlr" },
  { naptan: "940GZZDLHEQ", name: "Heron Quays DLR Station", mode: "dlr" },
  { naptan: "940GZZDLSTL", name: "Star Lane DLR Station", mode: "dlr" },
  { naptan: "940GZZDLWLA", name: "Woolwich Arsenal DLR Station", mode: "dlr" },
  { naptan: "940GZZDLWSV", name: "West Silvertown Station", mode: "dlr" },
  { naptan: "940GZZDLBLA", name: "Blackwall DLR Station", mode: "dlr" },
  { naptan: "940GZZDLPRE", name: "Prince Regent DLR Station", mode: "dlr" },
  { naptan: "940GZZDLRAL", name: "Royal Albert DLR Station", mode: "dlr" },
  { naptan: "940GZZDLCAN", name: "Canary Wharf DLR Station", mode: "dlr" },
  { naptan: "940GZZDLBPK", name: "Beckton Park DLR Station", mode: "dlr" },
  { naptan: "940GZZDLLIM", name: "Limehouse DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLSIT",
    name: "Stratford International DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLELV", name: "Elverson Road DLR Station", mode: "dlr" },
  { naptan: "940GZZDLWIQ", name: "West India Quay DLR Station", mode: "dlr" },
  { naptan: "940GZZDLLDP", name: "Langdon Park DLR Station", mode: "dlr" },
  { naptan: "940GZZDLCYP", name: "Cyprus DLR Station", mode: "dlr" },
  { naptan: "940GZZDLTWG", name: "Tower Gateway DLR Station", mode: "dlr" },
  { naptan: "940GZZDLSOQ", name: "South Quay DLR Station", mode: "dlr" },
  { naptan: "940GZZDLBNK", name: "Bank DLR Station", mode: "dlr" },
  { naptan: "940GZZDLSHA", name: "Shadwell DLR Station", mode: "dlr" },
  {
    naptan: "940GZZDLCUS",
    name: "Custom House for ExCeL DLR Station",
    mode: "dlr",
  },
  { naptan: "940GZZDLGAL", name: "Gallions Reach DLR Station", mode: "dlr" },
  { naptan: "940GZZDLPDK", name: "Pontoon Dock DLR Station", mode: "dlr" },
  { naptan: "940GZZDLPUD", name: "Pudding Mill Lane DLR Station", mode: "dlr" },
  { naptan: "940GZZDLRAL", name: "Royal Albert Dlr Station", mode: "dlr" },
];
