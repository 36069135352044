import { APP_KEY } from "./auth";
import { ArrivalTimeData } from "../types";
import { uniq } from "ramda";
import { trimStationLabels } from "../utils/index";

export const fetchArrivalTimes = async (
  naptan: string,
  setter: React.Dispatch<React.SetStateAction<ArrivalTimeData[]>>
) => {
  if (naptan === "") return;

  const requestUrl =
    "https://api.tfl.gov.uk/StopPoint/" +
    naptan +
    "/Arrivals?app_key=" +
    APP_KEY;

  console.log("fetching data from ", requestUrl);

  fetch(requestUrl)
    .then((response) => response.json())
    .then((data) => deserializeResponse(data))
    .then((data) => trimStationNames(data))
    .then((data) => uniq(data))
    .then((data) => data.sort((a, b) => a.timeToStation - b.timeToStation))
    .then((data) => numberStations(data))
    .then((data) => identityPrint(data))
    .then((data) => setter(data))
    .catch((e) => console.error("error occurred", e));
};

type ArrivalTimeResponse = ArrivalTimeResponseItem[] & {
  httpStatusCode?: number;
};

type ArrivalTimeResponseItem = {
  destinationName: string;
  destinationNaptanId: string;
  platformName: string;
  expectedArrival: string;
  timeToStation: number;
  direction: string;
  lineId: string;
  lineName: string;
  currentLocation: string;
};

const identityPrint = (d: ArrivalTimeData[]): ArrivalTimeData[] => {
  console.log(d);
  return d;
};

const deserializeResponse = (json: ArrivalTimeResponse): ArrivalTimeData[] => {
  if (json.httpStatusCode === 400) return [];
  return json.map((item) => ({
    destination: item.destinationName,
    timeToStation: item.timeToStation,
    direction: item.direction,
    currentLocation: item.currentLocation,
    lineId: item.lineId,
    lineName: item.lineName,
    expectedArrival: item.expectedArrival,
    platformName: item.platformName,
  }));
};

export const trimStationNames = (
  data: ArrivalTimeData[]
): ArrivalTimeData[] => {
  return data.map((d) => ({
    ...d,
    destination: trimStationLabels(d.destination),
  }));
};

const numberStations = (data: ArrivalTimeData[]): ArrivalTimeData[] => {
  return data.map((d, i) => ({ ...d, number: (i + 1).toString() }));
};

const filterByDirection = (
  data: ArrivalTimeData[],
  direction: string
): ArrivalTimeData[] => {
  return data.filter((d) => d.direction === direction);
};
