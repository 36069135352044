import { useState, useEffect } from "react";
import { Time } from "../types";

export const useTickingClock: () => Time = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return {
    hours: zeroPaddedString(currentTime.getHours()),
    minutes: zeroPaddedString(currentTime.getMinutes()),
    seconds: zeroPaddedString(currentTime.getSeconds()),
  };
};

const zeroPaddedString = (time: number): string => {
  const str = time.toString();
  return str.length !== 2 ? "0" + str : str;
};
