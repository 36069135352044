import React, { FC, useEffect } from "react";
import { Radio } from "antd";
import "./DirectionSelector.css";

const defaultDirection: string = "inbound";

type DirectionSelectorProps = {
  direction: string;
  directionSetter: React.Dispatch<React.SetStateAction<string>>;
};

export const DirectionSelector: FC<DirectionSelectorProps> = ({
  direction,
  directionSetter,
}) => {
  useEffect(() => {
    directionSetter(defaultDirection);
  }, [directionSetter]);

  return (
    <div className="direction-selector-container">
      <Radio.Group
        value={direction}
        defaultValue={defaultDirection}
        size="large"
        className="direction-radio-group"
        onChange={(e) => {
          directionSetter(e.target.value);
        }}
      >
        <Radio.Button value="inbound">inbound</Radio.Button>
        <Radio.Button value="outbound">outbound</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export {};
