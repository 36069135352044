import React, { useState, useEffect } from "react";
import "./App.css";
import { Board } from "./components/Board";
import { StationSelector } from "./components/StationSelector";
import { DirectionSelector } from "./components/DirectionSelector";
import { LineSelector } from "./components/LineSelector";
import { StationSign } from "./components/StationSign";
import { Station, ArrivalTimeData } from "./types";
import { fetchArrivalTimes } from "./api/";

function App() {
  const [selectedStation, setSelectedStation] = useState<Station>({
    name: "",
    naptan: "",
    mode: "",
  });
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [loading, setLoading] = useState(true);

  const [arrivalTimes, setArrivalTimes] = useState<ArrivalTimeData[]>([]);
  useEffect(() => {
    const fetch = () => {
      setLoading(true);
      fetchArrivalTimes(selectedStation.naptan, setArrivalTimes);
      setLoading(false);
    };
    fetch();
    const interval = setInterval(() => {
      fetch();
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedStation.naptan]);

  return (
    <div className="App">
      <StationSelector
        stationSetter={setSelectedStation}
        lineSetter={setSelectedLine}
      />
      <Board
        arrivalTimes={arrivalTimes}
        line={selectedLine}
        direction={selectedDirection}
        minimumLines={3}
        maximumLines={3}
        loading={loading}
      />
      <StationSign station={selectedStation} />
      <DirectionSelector
        direction={selectedDirection}
        directionSetter={setSelectedDirection}
      />
      <LineSelector
        line={selectedLine}
        lineSetter={setSelectedLine}
        arrivals={arrivalTimes}
      />
    </div>
  );
}

export default App;
